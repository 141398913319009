.skills-header {
    /* display: flex; */
    margin-bottom: .8rem;
    /* padding-left: 1rem; */
    text-align: center;
}

.so-far {
    padding-top: 1px;
    padding-bottom: 20px;
}

.skills__container {
    margin-left: 0.875rem;
}

.skills__data {
    background-color: var(--container-color);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    text-align: center;
}

.skills__info {
    margin-bottom: 10px;
    padding: 2rem 2rem 0 2rem;
}

.my-skills {
    margin-bottom: 1px;
}

.devicons {
    font-size: 5rem;
}

.devicon {
    float: left;
    width: 20%;
    padding-bottom: 10px;
}

@media screen and (max-width: 950px) {
    .devicon {
        width: 33.33%;
    }
    .devicons {
        font-size: 4rem;
    }
}

@media screen and (max-width: 650px) {
    .devicons {
        font-size: 3rem;
    }
}


