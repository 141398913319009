/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap');
/* Google Fonts */

/* ==================== Variables  CSS ==================== */
:root {
  /* Colors */
  /* Color mode HSL(hue, saturation, lightness) */
  --first-color: hsl(353, 100%, 65%);
  --title-color: hsl(244, 24%, 26%);
  --text-color: hsl(244, 16%, 43%);
  --body-color: hsl(258, 60%, 98%);
  --container-color: #fff;

  /* Font and Typography */
  /* .5rem = 8px | 1rem = 16px ...  */
  --body-font: 'Rubik', sans-serif;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;

  /* Font Weight */
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  
  /* Box Shadow */
  --shadow: 0px 5px 20px 0px rgb(69 67 96 / 10%);

  /* Border Radius */
  --border-radius: 20px;
}

/* Responsive typography */
@media screen and (max-width: 1024px) {
  :root {
    --h1-font-size: 1.75rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.9rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/* ==================== Base ==================== */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body, button, input, textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  line-height: 1.7;
  color: var(--text-color);
}

h1, h2, h3 {
  color: var(--title-color);
  font-weight: var(--font-semibold);
  line-height: 1.2;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/* ==================== Reusable CSS Classes ==================== */
.container {
  max-width: 1080px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.grid {
  display: grid;
}

.section {
  padding-top: 7rem;
  padding-bottom: 2rem;
}

.section__title {
  font-size: var(--h1-font-size);
  font-weight: var(--font-bold);
  position: relative;
  margin-bottom: 3.75rem;
  text-align: center;
}

.section__title::before {
  content: '';
  height: 2.25rem;
  width: 2.25rem;
  position: absolute;
  left: -0.875rem;
  right: -0.875rem;
}

.btn {
  padding: 0.75rem 2rem;
  line-height: 1;
  border-radius: 1.875rem;
  box-shadow: 0 0 1px rgb(0 0 0 /0%);
  color: white;
  display: inline-block;
  background-color: lightblue;
  font-weight: var(--font-bold);
}

@keyframes button-push {
  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.btn:hover {
  animation: button-push 0.3s linear 1;
}


