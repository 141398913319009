.about__container {
    margin-left: 0.875rem;
}

.about__data {
    background-color: var(--container-color);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
}

.about__description {
    margin-bottom: 1px;
    padding: 1rem 1.5rem 0 1.5rem;
}

.about__title {
    margin-bottom: 10px;
}

.beliefs {
    padding-top: 0px;
}

.about__title-section {
    text-align: center;
    margin-bottom: 20px;
}

.about__info {
    margin-bottom: 15px;
}
